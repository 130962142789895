import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";
import { TableContainer } from "components/Common";
import { getAllClaims } from "helpers/Claims/ClaimForm";
import {
  NotificationMessage,
  apiErrorrHandler,
  cleanObject,
  encryptText,
  fieldFinder,
  generateColumns,
} from "utils";
import { useSelector } from "react-redux";
import {
  claimAdmissionDetailsId,
  claimDetailsModuleId,
  claimFormModuleId,
} from "utils/modulesIds";
import {
  allowedPrintPermissionStatuses,
  buttonsTitles,
  modalHeaders,
  modalsStates,
} from "common/data";
import { SingleRowAction } from "../Common/Tables";
import { claimPaths } from "common/data/routePaths";
import {
  getAllActions,
  getAllSubModules,
} from "helpers/Permissions/permissions_helper";

import { Link } from "react-router-dom";
import CustomButton from "components/TP/Common/General/CustomButton";
import { AsyncSearchSelect, FormControll } from "../Common/Forms";
import MultiSkeleton from "../Common/MultiSkeleton";
import {
  BENEFECARIES,
  BENEFITS,
  HEALTHCAREPROVIDER,
  MEDICALCODES,
  PREDEFINEDS,
} from "helpers/url_helper";
import { getAllHealthCareProviders } from "helpers/Stakeholders/healthCare";
import { getAllBenefits } from "helpers/CommonData/benefits";
import { getAllPredefineds } from "helpers/Settings/predefineds/predefineds_herlper";
import { getAllMedicalCodes } from "helpers/CommonData/medicalCodes";
import { omitBy, isEmpty } from "lodash";
import MedicalHistoryDetails from "./MedicalHistoryDetails";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { fetcher } from "utils/API/SWRFetcher";
import useSWR from "swr";
import EndorsementHistorymodal from "./Forms/EndorsementHistorymodal";
import BeneficiaryDependencyModal from "./Forms/BeneficiaryDependencyModal";
import ViewFOBSpentModal from "../Beneficiary/ViewFOBSpentModal";
import FOBSpentModal from "../Beneficiary/FOBSpentModal";

// this component could be imported in claim
// of beneficiary
// in beneficiary we need to fetch claim permission
// in claim we will have it already so no need to fetch it again
const MedicalFilesTableModal = ({
  show,
  onCloseClick,
  beneficiaryId,
  notInClaim,
  t,
}) => {
  const url = `${BENEFECARIES}/${beneficiaryId}`;

  // get beneficiary
  const {
    isLoading: beneficiaryLoading,
    error: beneficiaryError,
    data: beneficiary,
  } = useSWR(
    () => (url && show ? [url] : null),
    ([url]) => fetcher(url),
    {
      revalidateOnFocus: false,
    }
  );

  const [claims, setClaims] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [columns, setColumns] = useState([]);
  const [admissionFields, setAdmissionFields] = useState([]);
  const [actionFields, setActionFields] = useState([]);
  const [isEndorsementModal, setIsEndorsementModal] = useState(false);
  const [isDependencyTableModal, setIsDependencyTableModal] = useState(false);

  // in this file
  const [isModal, setIsModal] = useState(false);

  // filters
  const [advancedFilters, setAdvancedFilters] = useState({});

  const { claimFormFields, moduleId, claimTableFields } = useSelector(
    (state) => ({
      loading: state?.Module?.loading,
      claimTableFields: state?.Module?.actions?.fields,
      moduleId: state?.Module?.actions?.id,
      claimFormFields: state?.Module?.subModules
        ?.find((item) => item.id === claimDetailsModuleId)
        ?.sub_modules?.find((item) => item.id === claimAdmissionDetailsId)
        ?.fields,
    })
  );
  const isMobile = useMediaQuery("(max-width: 500px)");

  useEffect(() => {
    // routed to , last updated at and updated By
    const unwatnedFields = [1606, 1605, 1663, 1460];

    if (actionFields?.length > 0) {
      const generatedColumns = generateColumns([
        ...actionFields.filter((item) => !unwatnedFields.includes(item.id)),
        {
          label_name: "Type",
          index_name: "type.name",
          name: "type.name",
          display_order: 2,
        },
        {
          label_name: "Copart Amount",
          index_name: "copart_amount_in_base_currency",
          name: "copart_amount_in_base_currency",
          field_type: "number",
        },
        {
          label_name: "Discharge Date",
          index_name: "discharge_date",
          name: "discharge_date",
          field_type: "date",
          display_order: 3.1,
        },
        { ...fieldFinder(admissionFields, 712), display_order: 4.1 },
        {
          label_name: "ICD",
          index_name: "last_icd.name",
          name: "last_icd.name",
          field_type: "text",
          display_order: 4.1,
          disableSortBy: true,
        },
        fieldFinder(admissionFields, 415),
        fieldFinder(admissionFields, 401),
      ]);

      setColumns([
        ...generatedColumns
          .filter(
            (item) =>
              item.accessor !== "beneficiary.name" &&
              item.accessor !== "beneficiary.code" &&
              item.accessor !== "policy.payer.first_name" &&
              item.accessor !== "beneficiary.policy.name" &&
              item.accessor !== "estimation_amount" &&
              item.accessor !== "total_approved_amount" &&
              item.accessor !== "cause.name" &&
              item.accessor !== "internal_notes" &&
              item.accessor !== "authorization_notes"
          )
          .map((column) => {
            return { ...column, disableSortBy: true };
          }),
        {
          Header: buttonsTitles.actions,
          accessor: "actions",
          disableFilters: true,
          Cell: (cellProps) => {
            // Actions Icons in each row with its props
            const row = cellProps.row.original;
            return (
              <div className="d-flex gap-2">
                <SingleRowAction
                  actionName="view"
                  target="_blank"
                  to={`${claimPaths.claimForm}/view/${row?.id}`}
                  iconClass="mdi mdi-eye"
                  record={row}
                />
                {allowedPrintPermissionStatuses.includes(row?.status?.id) && (
                  <SingleRowAction
                    actionName="Print"
                    target="_blank"
                    to={`${claimPaths.claimForm}/print/${row?.id}`}
                    iconClass="mdi mdi-cloud-print-outline"
                    record={row}
                  />
                )}
              </div>
            );
          },
        },
      ]);
    }
  }, [actionFields]);

  const getLastMedicalCode = (medicalCodesArray) => {
    let lastCode;
    // filter medical codes with discharge 0 and medical code type is 2 or 3

    const filteredCodes = medicalCodesArray?.filter(
      (item) =>
        !item.discharge &&
        (item.medical_code_type?.id === 2 || item.medical_code_type?.id === 3)
    );
    if (filteredCodes.length > 0) {
      // return the last code
      lastCode = filteredCodes[filteredCodes.length - 1];
    } else {
      lastCode = "";
    }
    return lastCode;
  };

  const getClaims = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
  }) => {
    try {
      setIsLoading(true);
      const res = await getAllClaims({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        beneficiary_id: beneficiaryId,
      });
      const resClaims = res.items;
      setClaims(
        resClaims.map((item) => ({
          ...item,
          // last item of medical codes with discharge 0 and medical code type is 2 or 3
          last_icd: getLastMedicalCode(item.medical_codes),
        }))
      );
      setPagination({
        size: res.size,
        page: res.page,
        per_page: res.per_page,
        total_pages: res.total_pages,
        total: res.total_size,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    }
  };

  const getClaimPermission = async () => {
    setLoading(true);
    try {
      const level2Res = await getAllActions(claimFormModuleId);
      const level3Res = await getAllSubModules(claimFormModuleId);
      setActionFields(level2Res.item.fields);
      const admissionModulefields = level3Res.item.sub_modules
        ?.find((item) => item.id === claimDetailsModuleId)
        ?.sub_modules?.find(
          (item) => item.id === claimAdmissionDetailsId
        )?.fields;
      setAdmissionFields(admissionModulefields);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // fetch claim permission if the component isn’t in the claim form

  useEffect(() => {
    if (notInClaim) {
      getClaimPermission();
    } else {
      setActionFields(claimTableFields);
      setAdmissionFields(claimFormFields);
    }
  }, [notInClaim]);

  return (
    <Fragment>
      <Modal
        size="xl"
        isOpen={show}
        toggle={onCloseClick}
        backdrop="static"
        className="modal-fullscreen"
      >
        <div className="modal-header">
          {/* Modal Header Title */}
          <h5 className="modal-title mt-0" id="myModalLabel">
            {beneficiaryLoading ? (
              <MultiSkeleton length={1} />
            ) : (
              <Fragment>
                {`${modalHeaders.medicalFile}: ${beneficiary?.name}`}
              </Fragment>
            )}
          </h5>

          {/* close button on the top right section */}
          <button
            aria-label="Close Modal"
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
        </div>

        {/* Modal Body */}
        <div className="modal-body">
          {/* sticky notes data */}
          {beneficiaryLoading ? (
            <MultiSkeleton length={2} />
          ) : (
            <Fragment>
              <div
                id="sticky-top-boxes"
                style={{ top: "0", marginBottom: "20px" }}
              >
                <MedicalHistoryDetails beneficiary={beneficiary} />
              </div>
              <Row className="mt-2">
                <Col md={6}>
                  <FormControll
                    id="287"
                    name="special_notes"
                    type="textarea"
                    inputField={{ label_name: "Special Notes" }}
                    value={
                      beneficiary?.special_notes
                        ? beneficiary?.special_notes
                        : ""
                    }
                    wrapperClassNames="mb-3"
                    readOnly={true}
                  />
                </Col>
                <Col md={6}>
                  <FormControll
                    id="792"
                    name="memo"
                    type="textarea"
                    inputField={{ label_name: "Memo" }}
                    value={beneficiary?.memo ? beneficiary?.memo : ""}
                    wrapperClassNames="mb-3"
                    readOnly={true}
                  />
                </Col>
              </Row>
              <div className="d-flex align-items-center my-4 gap-2">
                {/* if no pin number for this beneficiary display disabled button */}
                {beneficiary?.pin_number ? (
                  <Link
                    to={`${claimPaths.claimHistory}/${encryptText(
                      JSON.stringify({
                        beneficiaries_pin_number: beneficiary?.pin_number,
                      })
                    )}`}
                    target="_blank"
                    className="btn btn-primary"
                  >
                    {buttonsTitles.claimHistory}
                  </Link>
                ) : (
                  <CustomButton
                    disabled={true}
                    disabledTooltip={"No Pin Number Assigned For This Member"}
                    className="btn btn-primary"
                    title="Claim History"
                  />
                )}

                {/* View Dependency */}
                {/* {beneficiary?.group ? null : (
                  <Link
                    to={`${stakeholderPaths.subBeneficiariesList}/${beneficiaryId}`}
                    target="_blank"
                    className="btn btn-gray-600"
                  >
                    Dependency
                  </Link>
                )} */}
                <button
                  className="btn btn-gray-600"
                  aria-label="Beneficiary dependency"
                  onClick={() => setIsDependencyTableModal(true)}
                >
                  Dependency
                </button>

                {/* Endorsement Dropdown */}
                {beneficiary?.is_active === 1 ? (
                  <button
                    aria-label="Beneficiary Endorsement"
                    className="btn btn-secondary"
                    onClick={() => setIsEndorsementModal(true)}
                  >
                    {buttonsTitles.endorsementHistory}
                  </button>
                ) : // <EndoresmentDropdown
                //   first_name={beneficiary?.first_name}
                //   father_name={beneficiary?.father_name}
                //   last_name={beneficiary?.last_name}
                //   birth_date={beneficiary?.birth_date}
                //   selectedPlans={beneficiary?.co_participations}
                //   policy={beneficiary?.policy}
                //   principle={beneficiary?.group}
                //   beneficary={beneficiary}
                // />
                null}
              </div>
            </Fragment>
          )}

          {/* filters */}
          {!isMobile && (
            <>
              {loading ? (
                <MultiSkeleton length={1} />
              ) : (
                <Row className="align-items-center gap-0">
                  <Col md={2}>
                    <AsyncSearchSelect
                      id="400"
                      name="healthcare_provider_id"
                      inputField={fieldFinder(actionFields, 400)}
                      placeholder="Search By Name"
                      value={
                        advancedFilters[fieldFinder(actionFields, 400)?.name] ||
                        ""
                      }
                      customOnChange={(selectedValue, { actions }) => {
                        setAdvancedFilters((prev) => {
                          return {
                            ...prev,
                            [fieldFinder(actionFields, 400)?.name]:
                              selectedValue ? selectedValue?.first_name : "",
                          };
                        });
                      }}
                      url={HEALTHCAREPROVIDER}
                      getOptionsData={getAllHealthCareProviders}
                      disableFetch={true}
                      clearable={true}
                      onBlur={() => {}}
                      searchKey="first_name"
                      mappingLabel="first_name"
                      mappingValue="id"
                      wrapperClassNames="mb-3"
                    />
                  </Col>
                  <Col md={2}>
                    <AsyncSearchSelect
                      id="386"
                      name="benefit_id"
                      inputField={fieldFinder(actionFields, 386)}
                      placeholder="Search By Name"
                      value={
                        advancedFilters[fieldFinder(actionFields, 386)?.name] ||
                        ""
                      }
                      customOnChange={(selectedValue, { actions }) => {
                        setAdvancedFilters((prev) => {
                          return {
                            ...prev,
                            [fieldFinder(actionFields, 386)?.name]:
                              selectedValue ? selectedValue?.name : "",
                            benefit_id: selectedValue ? selectedValue?.id : "",
                          };
                        });
                      }}
                      url={BENEFITS}
                      getOptionsData={getAllBenefits}
                      urlAddOns={{
                        is_active: 1,
                        class_allowed: 1,
                        has_slug: 1,
                      }}
                      clearable={true}
                      onBlur={() => {}}
                      searchKey="name"
                      mappingLabel="name"
                      mappingValue="id"
                      wrapperClassNames="mb-3"
                    />
                  </Col>
                  <Col md={2}>
                    <AsyncSearchSelect
                      id="708"
                      name="status_id"
                      inputField={fieldFinder(actionFields, 708)}
                      placeholder="Search By Name"
                      value={
                        advancedFilters[fieldFinder(actionFields, 708)?.name] ||
                        ""
                      }
                      customOnChange={(selectedValue) => {
                        setAdvancedFilters((prev) => {
                          return {
                            ...prev,
                            [fieldFinder(actionFields, 708)?.name]:
                              selectedValue ? selectedValue?.name : "",
                          };
                        });
                      }}
                      url={`${PREDEFINEDS}?parent_slug=${
                        fieldFinder(actionFields, 708)?.predefined_value?.slug
                      }`}
                      getOptionsData={getAllPredefineds}
                      urlAddOns={{
                        parent_slug: fieldFinder(actionFields, 708)
                          ?.predefined_value?.slug,
                      }}
                      disableFetch={true}
                      clearable={true}
                      onBlur={() => {}}
                      searchKey="name"
                      mappingLabel="name"
                      mappingValue="id"
                      wrapperClassNames="mb-3"
                    />
                  </Col>
                  <Col md={2}>
                    <AsyncSearchSelect
                      id="712"
                      name="general_assessment_id"
                      inputField={{
                        ...fieldFinder(admissionFields, 712),
                        is_required: 0,
                      }}
                      value={advancedFilters["general_assessment_id"] || ""}
                      customOnChange={(selectedValue) => {
                        setAdvancedFilters((prev) => {
                          return {
                            ...prev,
                            ["general_assessment_id"]: selectedValue
                              ? `${selectedValue?.id}`
                              : "",
                          };
                        });
                      }}
                      urlAddOns={{
                        medical_code_type_id: 2,
                        parent_id: "",
                      }}
                      url={MEDICALCODES}
                      getOptionsData={getAllMedicalCodes}
                      disableFetch={true}
                      clearable={true}
                      onBlur={() => {}}
                      searchKey="name"
                      mappingLabel="name"
                      mappingValue="id"
                      wrapperClassNames="mb-3"
                    />
                  </Col>

                  <Col md={1}>
                    <div className="d-flex align-items-center gap-2 mt-2">
                      <CustomButton
                        className="btn btn-secondary w-md"
                        onClick={() => {
                          const removedEmpty = cleanObject(advancedFilters);
                          getClaims({
                            page: 1,
                            per_page: 10,
                            search_keys: Object.keys(removedEmpty).join(","),
                            search_values:
                              Object.values(removedEmpty).join(","),
                          });
                        }}
                        disabled={
                          Object.keys(cleanObject(advancedFilters)).length ===
                            0 || isLoading
                            ? true
                            : false
                        }
                        title={buttonsTitles.filter}
                      />

                      {/* <ViewFOBSpentModal
                        beneficiary={beneficiary}
                        benefitId={advancedFilters?.benefit_id}
                        policyId={beneficiary?.policy?.id}
                        disabled={!advancedFilters?.benefit_id || !beneficiary}
                      /> */}

                      <button
                        type="button"
                        className="btn btn-secondary w-md"
                        onClick={() => setIsModal(modalsStates.fobSpent)}
                        disabled={!advancedFilters?.benefit_id || !beneficiary}
                      >
                        {buttonsTitles.fobSpent}
                      </button>
                      <CustomButton
                        className="btn btn-gray-600 w-md"
                        disabled={
                          Object.keys(cleanObject(advancedFilters)).length ===
                            0 || isLoading
                            ? true
                            : false
                        }
                        onClick={() => {
                          setAdvancedFilters({});
                          getClaims({
                            page: 1,
                            per_page: 10,
                          });
                        }}
                        title={buttonsTitles.clear}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}

          <TableContainer
            removeActionsColumns={true}
            // removeActionsButtons={true}
            columns={columns}
            data={claims}
            handleAdd={null}
            pagination={pagination}
            queryAddOn={{
              beneficiary_id: beneficiaryId,

              search_keys: Object.keys(omitBy(advancedFilters, isEmpty)).join(
                ","
              ),
              search_values: Object.values(
                omitBy(advancedFilters, isEmpty)
              ).join(","),
            }}
            getData={getClaims}
            loading={isLoading}
            basicTable={true}
            sheetName="Claim Medical Files"
          />
        </div>

        <div className="modal-footer">
          <button
            aria-label="Close Modal"
            type="button"
            className="btn btn-secondary"
            onClick={onCloseClick}
          >
            {buttonsTitles.close}
          </button>
        </div>
      </Modal>
      {isEndorsementModal && (
        <EndorsementHistorymodal
          show={isEndorsementModal}
          onCloseClick={() => setIsEndorsementModal(false)}
          beneficiaryId={beneficiary?.id}
          policyId={beneficiary?.policy?.id}
          beneficiary={beneficiary}
          t={t}
        />
      )}
      {isDependencyTableModal && (
        <BeneficiaryDependencyModal
          show={isDependencyTableModal}
          onCloseClick={() => setIsDependencyTableModal(false)}
          beneficiaryId={beneficiary?.id}
          beneficiary={beneficiary}
          t={t}
        />
      )}

      {isModal === modalsStates.fobSpent && (
        <FOBSpentModal
          show={isModal === modalsStates.fobSpent}
          onCloseClick={() => setIsModal(false)}
          beneficiaryId={beneficiary?.id}
          beneficiary={beneficiary}
          benefitId={advancedFilters?.benefit_id}
          policyId={beneficiary?.policy?.id}
        />
      )}
    </Fragment>
  );
};

MedicalFilesTableModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  isSaving: PropTypes.bool,
  children: PropTypes.any,
  beneficiaryId: PropTypes.number,
  // modalTitle: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default withTranslation()(MedicalFilesTableModal);
