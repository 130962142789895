export const stakeholderPaths = {
  reinsurer: "/reinsurer",
  physician: "/physician",
  payer: "/payer",
  healthcare_providers: "/healthcare_provider",
  beneficiaryList: "/beneficiary-list",
  broker: "/broker",
  bulkPrintCards: "bulk-card-print",
  endorsementHistory: "/policy-endorsement",
  subBeneficiariesList: "/sub-beneficiary-list",
};
