/* Paths */
import { reportPaths } from "common/data/routePaths";
import { lazy } from "react";
/*
Components
*/

const DynamicReports = lazy(() => import("pages/Reports/DynamicReports"));
const BlackListedReports = lazy(() =>
  import("pages/Reports/BlackListedReports")
);
const DueToExpiryReport = lazy(() => import("pages/Reports/DueToExpiryReport"));
const AgentComissionReports = lazy(() =>
  import("pages/Reports/AgentComissionReports")
);
const NotRenewedReports = lazy(() => import("pages/Reports/NotRenewedReport"));
const AgentPoliciesReports = lazy(() =>
  import("pages/Reports/AgentPoliciesReports")
);
const VolumeRebateReport = lazy(() =>
  import("pages/Reports/VolumeRebateReport")
);
const FeesByBeneficiary = lazy(() => import("pages/Reports/FeesByBeneficiary"));
const FeesByClaims = lazy(() => import("pages/Reports/FeesByClaims"));
const PremiumFees = lazy(() => import("pages/Reports/PremiumFeesreports"));

// loss ratio
const LossRatio = lazy(() => import("pages/Reports/LossRatio"));
// loss ratio
const LossRatioV2 = lazy(() => import("pages/Reports/LossRatioV2"));
// Insurer Score board
const InsurerScoreboard = lazy(() => import("pages/Reports/InsurerScoreboard"));
//priority payer report
const PriorityPayerReport = lazy(() =>
  import("pages/Reports/PriorityPayerReport")
);

// Production Report
const ProductionReports = lazy(() => import("pages/Reports/ProductionReports"));
// Claim Report
const ClaimReport = lazy(() => import("pages/Reports/ClaimReport"));
// GB Claim Report
const GBClaimReport = lazy(() => import("pages/Reports/GBClaimReport"));

// PRemium Report
const PremiumReport = lazy(() => import("pages/Reports/PremiumReport"));
const FnolReport = lazy(() => import("pages/Reports/FnolReport"));
// munichre
const MunichreReport = lazy(() => import("pages/Reports/MunichreReport"));
const PayersPolicies = lazy(() => import("pages/Reports/PayersPolicies"));

const {
  dynamicReport,
  blackListedReport,
  dueToExpirtyReport,
  agentComissionReport,
  notRenewedReports,
  agentPoliciesReports,
  volumeRebateReport,
  feesByBeneficiaryReport,
  feesByClaimsReport,
  premiumFees,
  lossRatio,
  lossRatioV2,
  insurerScoreboard,
  priorityPayer,
  production,
  claimReport,
  premiumReport,
  fnolReport,
  gbClaimReport,
  munichre,
  payersPolicies,
} = reportPaths;

export const ReportsRoutes = [
  // dynamic reports
  { path: dynamicReport, component: DynamicReports },
  // blacklisted reports
  { path: blackListedReport, component: BlackListedReports },
  // due to expiry reports
  { path: dueToExpirtyReport, component: DueToExpiryReport },
  // Agent Comission Reports reports
  { path: agentComissionReport, component: AgentComissionReports },
  // not renewed reports
  { path: notRenewedReports, component: NotRenewedReports },
  // agent policies reports
  { path: agentPoliciesReports, component: AgentPoliciesReports },
  // volume rebate report
  { path: volumeRebateReport, component: VolumeRebateReport },

  // fees by beneficiary report
  { path: feesByBeneficiaryReport, component: FeesByBeneficiary },
  // fees by claims report
  { path: feesByClaimsReport, component: FeesByClaims },
  // premium fees report
  { path: premiumFees, component: PremiumFees },
  // loss ratio report
  { path: lossRatio, component: LossRatio },
  // loss ratio V2 report
  { path: lossRatioV2, component: LossRatioV2 },
  // insurer score board
  { path: insurerScoreboard, component: InsurerScoreboard },
  // priority payer report
  { path: priorityPayer, component: PriorityPayerReport },
  // Production Report
  { path: production, component: ProductionReports },
  // Claim Report
  { path: claimReport, component: ClaimReport },
  // GB Claim Report
  { path: gbClaimReport, component: GBClaimReport },
  // Premium Report
  { path: premiumReport, component: PremiumReport },
  { path: fnolReport, component: FnolReport },

  // Munich-re
  { path: munichre, component: MunichreReport },
  {
    path: payersPolicies,
    component: PayersPolicies,
  },
];
