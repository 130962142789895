import { FormModal, SearchSelect } from "components/TP/Common/Forms";
import { useFormik } from "formik";
import { updateFnol } from "helpers/Motors/fnol_helper";
import { BRANCHES } from "helpers/url_helper";
import React, { Fragment, useState } from "react";
import {
  apiErrorrHandler,
  decryptText,
  getLoggedInUser,
  getMainPayer,
  isInputInvalid,
  NotificationMessage,
} from "utils";
import * as Yup from "yup";

const RouteToBranchModal = ({ isModal, setIsModal, rowId }) => {
  const [error, setError] = useState("");
  //bulk change status
  const handleUpdateFnolBranch = async (values, resetForm) => {
    try {
      const res = await updateFnol({
        id: rowId,
        branch_id: validation.values.branch_id,
      });
      NotificationMessage("success", "update");
      resetForm();
      setIsModal(false);
      setError("");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      setError(errorMessage);
    } finally {
    }
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branch_id: "",
    },
    validationSchema: Yup.object({
      branch_id: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleUpdateFnolBranch(values, setSubmitting, resetForm);
    },
  });

  return (
    <FormModal
      modalSize="md"
      show={isModal}
      onCloseClick={() => {
        setIsModal(false);
        validation.resetForm();
        setError("");
      }}
      onSaveClick={validation.handleSubmit}
      modalTitle="Change Fnol Branch"
      isSaving={validation.isSubmitting}
      disableSave={!validation.values.branch_id}
    >
      <Fragment>
        <form onSubmit={(e) => e.preventDefault()}>
          <SearchSelect
            id="1572"
            name="branch_id"
            inputField={{ label_name: "Branch Name" }}
            value={validation.values.branch_id || null}
            onChange={validation.setFieldValue}
            onBlur={validation.setFieldTouched}
            url={BRANCHES}
            urlAddOns={{
              search_keys: "access_type_id",
              search_values: getLoggedInUser()?.access_type?.id,
              source_id: getMainPayer().id,
            }}
            mappingLabel="name"
            mappingValue="id"
            invalid={isInputInvalid(validation, "branch_id")}
            error={validation.errors.branch_id}
            wrapperClassNames="mb-3"
          />
        </form>
      </Fragment>
    </FormModal>
  );
};

export default RouteToBranchModal;
