import React, { Fragment } from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody, Col } from "reactstrap"

const ClaimOverview = ({ KPI }) => {
  const months = KPI?.data?.map((month) => month.month_name)

  //claim overview
  const stackedColumnsoptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: months,
    },
    colors: ["#556ee6", "#F1B44C", "#34C38F"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `$ ${val.toLocaleString("en-US")}`
        },
      },
    },
  }

  const benefitNames = new Set(
    KPI?.data?.flatMap((test) => test.benefits.map((benefit) => benefit.name))
  )
  const benefits = Array.from(benefitNames)

  const stackedColumnData = benefits.map((benefitName) => ({
    name: benefitName,
    data: KPI?.data?.map((monthBenefits) => {
      const Benefit = monthBenefits.benefits?.find(
        (monthBenefit) => monthBenefit.name === benefitName
      )
      return Benefit ? Number(Benefit.calculated_value.toFixed(2)) : 0
    }),
  }))
  //   const stackedColumnData = [
  // {
  //   name: "PM",
  //   data: [5000, 15000, 4000, 24000, 7500, 12000, 11000, 8000, 27000, 5000],
  // },
  // {
  //   name: "AMB",
  //   data: [5000, 10000, 7500, 2000, 5000, 10000, 7000, 10000, 5000, 5000],
  // },
  // {
  //   name: "IN",
  //   data: [8000, 15000, 7000, 6500, 12000, 22000, 6000, 7000, 8000, 5000],
  // },
  //   ]
  return (
    <Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title">{KPI?.name}</h4>
          <ReactApexChart
            options={stackedColumnsoptions}
            series={stackedColumnData}
            type="bar"
            height="419"
            className="apex-charts"
          />
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default ClaimOverview
