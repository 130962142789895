import { stakeholderPaths } from "common/data/routePaths";
import SpecialNotesModal from "components/TP/Common/Modals/SpecialNotesModal";
import MultiSkeleton from "components/TP/Common/MultiSkeleton";
import React, { Fragment, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { beneficaryBenefitTOBId } from "utils/modulesIds";
import MedicalFilesTableModal from "./MedicalFilesTableModal";
import { buttonsTitles, modalsStates } from "common/data";
import ChatModal from "../Chats/ChatModal";
import WhatssAppModal from "./WhatssAppModal";
import FocSpentModal from "../Beneficiary/FocSpentModal";
import ClaimChatNotification from "../Chats/ClaimChatNotification";
import ClaimHistoryActionsModal from "./ClaimHistoryActionsModal";
import FOBSpentModal from "../Beneficiary/FOBSpentModal";

const ClaimsBreadcrumbAddons = ({
  beneficiary,
  isLoading,
  claim,
  networkId,
}) => {
  const { claimId } = useParams();

  // store modal states for all modals
  // in this file
  const [isModal, setIsModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

  const isVIP =
    beneficiary &&
    beneficiary.client_class &&
    beneficiary.client_class.slug === "memberClass_vip";

  if (isLoading)
    return (
      <div className="bg-white text-danger rounded-1 p-2">
        <MultiSkeleton length={1} />
      </div>
    );

  return (
    !isLoading &&
    beneficiary && (
      <Fragment>
        <div className="d-flex align-items-center">
          {isVIP ? (
            <Badge
              color="primary"
              className="font-size-16 align-self-start ms-2"
            >
              {beneficiary?.client_class?.name}
            </Badge>
          ) : null}
          <ul className="list-unstyled hstack gap-1  ms-2 flex-wrap">
            <li>
              <Link
                className="badge bg-success fw-semibold py-2 px-2"
                to={`${stakeholderPaths.beneficiaryList}/view/${beneficiary?.id}`}
                target="_blank"
              >
                {buttonsTitles.viewProfile}
              </Link>
            </li>
            <li>
              <button
                aria-label="Beneficiary Medical File"
                className="border-0 badge bg-success fw-semibold py-2 px-2"
                onClick={() => setIsModal(modalsStates.medicalFilesModal)}
              >
                {buttonsTitles.medicalFile}
              </button>
            </li>

            <li>
              <Link
                className="badge bg-success fw-semibold py-2 px-2"
                target="_blank"
                to={{
                  pathname: `${stakeholderPaths.beneficiaryList}/view/${beneficiary?.id}#${beneficaryBenefitTOBId}`,
                  state: { id: beneficaryBenefitTOBId },
                }}
              >
                {buttonsTitles.tableofBenefits}
              </Link>
            </li>
            <li>
              <button
                className="badge border-0 bg-success fw-bold py-2 px-2"
                onClick={() => setIsModal(modalsStates.sepcialNotes)}
              >
                {buttonsTitles.viewNotes}
              </button>
            </li>

            {/* Actions Dropdown  */}

            <li>
              <Dropdown
                isOpen={isActionMenuOpen}
                toggle={() => setIsActionMenuOpen(!isActionMenuOpen)}
                className="align-self-center"
              >
                <DropdownToggle
                  tag="button"
                  className="badge border-0 bg-success fw-bold py-2 px-2 d-flex gap-2"
                  caret
                >
                  <span>{buttonsTitles.actions}</span>
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                  <button
                    aria-label="Beneficiary FOC Spent"
                    className="dropdown-item"
                    onClick={() => setIsModal(modalsStates.focSpent)}
                  >
                    {buttonsTitles.focSpent}
                  </button>
                  <button
                    aria-label="Beneficiary FOB Spent"
                    className="dropdown-item"
                    onClick={() => setIsModal(modalsStates.fobSpent)}
                  >
                    {buttonsTitles.fobSpent}
                  </button>

                  {/* Claim History */}

                  <button
                    className="dropdown-item"
                    aria-label={buttonsTitles.claimHistory}
                    onClick={() => setIsModal("CLAIM_HISTORY")}
                  >
                    {buttonsTitles.claimHistory}
                  </button>
                </DropdownMenu>
              </Dropdown>
            </li>

            {claimId ? (
              <li className="position-relative">
                <button
                  className="badge border-0 bg-success fw-bold py-2 px-2"
                  onClick={() => setIsModal(modalsStates.chatModal)}
                >
                  {buttonsTitles.chat}

                  <ClaimChatNotification claim={claim} inClaimForm={true} />
                </button>
              </li>
            ) : null}

            {/* send wattsapp button */}
            {claimId ? (
              <Fragment>
                <Dropdown
                  isOpen={isMenuOpen}
                  toggle={() => setIsMenuOpen(!isMenuOpen)}
                  className="align-self-center"
                  // className="badge border-0 bg-success fw-bold py-2 px-2"
                >
                  <DropdownToggle
                    tag="button"
                    className="badge border-0 bg-success fw-bold py-2 px-2 d-flex gap-2"
                    caret
                  >
                    <span>{buttonsTitles.send}</span>
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        setIsModal(modalsStates.whatAppModal);
                        setIsMenuOpen(false);
                      }}
                    >
                      {buttonsTitles.sendWhatsapp}
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        setIsMenuOpen(false);
                      }}
                    >
                      {buttonsTitles.sendEmail}
                    </button>
                  </DropdownMenu>
                </Dropdown>
              </Fragment>
            ) : null}
          </ul>
        </div>

        {isModal === modalsStates.sepcialNotes && (
          <SpecialNotesModal
            show={isModal === modalsStates.sepcialNotes}
            onCloseClick={() => setIsModal(false)}
            beneficaryId={beneficiary?.id}
            beneficary={beneficiary}
          />
        )}
        {isModal === modalsStates.chatModal && (
          <ChatModal
            show={isModal === modalsStates.chatModal}
            onCloseClick={() => setIsModal(false)}
            resourceId={claimId}
            beneficiary={beneficiary}
            claim={claim}
            // beneficary={beneficiary}
          />
        )}

        {isModal === modalsStates.medicalFilesModal && (
          <MedicalFilesTableModal
            show={isModal === modalsStates.medicalFilesModal}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
            beneficiary={beneficiary}
          />
        )}

        {isModal === modalsStates.focSpent && (
          <FocSpentModal
            show={isModal === modalsStates.focSpent}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
          />
        )}
        {isModal === modalsStates.fobSpent && (
          <FOBSpentModal
            show={isModal === modalsStates.fobSpent}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
            beneficiary={beneficiary}
          />
        )}
        {isModal === modalsStates.whatAppModal && (
          <WhatssAppModal
            isModal={isModal === modalsStates.whatAppModal}
            setIsModal={setIsModal}
            claim={claim}
          />
        )}

        {/* Claim History modal */}
        {isModal === "CLAIM_HISTORY" && (
          <ClaimHistoryActionsModal
            isModal={isModal === "CLAIM_HISTORY"}
            setIsModal={setIsModal}
          />
        )}
      </Fragment>
    )
  );
};

export default ClaimsBreadcrumbAddons;
