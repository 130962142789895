import { ModalSpinner } from "components/TP/Common/Forms"
import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
import { CardUser } from "components/TP/dashboards"
import AccidentByDate from "components/TP/dashboards/fnolDashboard/AccidentByDate"
import DamageServerity from "components/TP/dashboards/fnolDashboard/DamageServerity"
import ReportedBy from "components/TP/dashboards/fnolDashboard/ReportedBy"
import TopBrands from "components/TP/dashboards/fnolDashboard/TopBrands"
import TopRegions from "components/TP/dashboards/fnolDashboard/TopRegions"
import { APIDateFormat } from "constants/TPA"
import dayjs from "dayjs"
import { showDashboard, showKPI } from "helpers/General/dashboards_helper"
import moment from "moment"
import React, { Fragment, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import { apiErrorrHandler, getMainPayer, NotificationMessage } from "utils"
import { dashboardsID, fnolDashboardID } from "utils/modulesIds"
import TOPTenExperts from "components/TP/dashboards/fnolDashboard/TOPTenExperts"
import AccidentByType from "components/TP/dashboards/fnolDashboard/AccidentByType"
import AccidentByTime from "components/TP/dashboards/fnolDashboard/AccidentByTime"
import ProblemType from "components/TP/dashboards/fnolDashboard/ProblemType"

const FnolDashboard = (props) => {
  const { module } = useSelector((state) => ({
    module: state.Sidebar?.modules
      ?.find((item) => item.id === dashboardsID)
      ?.sub_modules?.find((item) => item.id === fnolDashboardID),
    loading: state.Sidebar?.loading,
  }))

  const dataColors =
    '["--bs-primary", "--bs-success", "--bs-danger" , "--bs-secondary"]'

  const [dashboardKPIs, setDashboardKPIs] = useState([])
  const [dateRange, setDateRange] = useState({
    start_date: dayjs().add(-7, "d").format(APIDateFormat).replace(/\//g, "-"),
    end_date: dayjs().format(APIDateFormat).replace(/\//g, "-"),
  })
  const [isLoading, setIsLoading] = useState(false)
  const [loadingSlug, setLoadingSlug] = useState("")
  const [kpiResponses, setKpiResponses] = useState({})
  //const [slugs, setSlugs] = useState([])

  //call dashboard to get all slugs
  const getDashboard = async (dashboardId, dates) => {
    setIsLoading(true)
    try {
      const response = await showDashboard(dashboardId, dates)
      setDashboardKPIs(response.item)
      setSlugs(response.item.map((dashboardItem) => dashboardItem.slug))
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }
  const slugs = useMemo(
    () => [
      "fnol-top_regions-by-accident-location-and-payer",
      "fnol-count-by-accident-date-and-payer",
      "fnol-count-by-reported-by-id-and-payer",
      "fnol-vehicles-by-brand-id-and-payer",
      "fnol-count-by-damage_severity-and-payer",
      "top-ten-experts-by-dispatch-count-and-payer",
      "fnol-count-by-accident-type-and-payer",
      "fnol-count-by-accident-time-and-payer",
      "fnol-count-by-problem-and-payer",
      "fnol-count-by-red-flag-id",
    ],
    []
  )

  //call kpi endpoint to get data of one slug
  const getKPI = async (slug, data) => {
    setLoadingSlug(`${slug}`)
    try {
      const response = await showKPI(slug, {
        start_date: moment(dateRange.start_date)
          .format(APIDateFormat)
          .replace(/\//g, "-"),
        end_date: moment(dateRange.end_date)
          .format(APIDateFormat)
          .replace(/\//g, "-"),
        ...data,
      })
      setKpiResponses((prevKpiResponses) => ({
        ...prevKpiResponses,
        [slug]: response.item,
      }))
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setLoadingSlug("")
    }
  }

  // useEffect(() => {
  //   getDashboard(fnolDashboardID, {
  //     start_date: moment(dateRange.start_date)
  //       .format(APIDateFormat)
  //       .replace(/\//g, "-"),
  //     end_date: moment(dateRange.end_date)
  //       .format(APIDateFormat)
  //       .replace(/\//g, "-"),
  //   });
  // }, []);

  useEffect(() => {
    if (getMainPayer() && slugs.length > 0) {
      getPayerKPI()
    }
  }, [slugs])

  // get kpi of each slug
  const getPayerKPI = () => {
    slugs.forEach((slug) => {
      let conditionKeys = []
      let conditionValues = []

      switch (slug) {
        case "fnol-top_regions-by-accident-location-and-payer":
        case "fnol-count-by-accident-date-and-payer":
        case "fnol-count-by-reported-by-id-and-payer":
        case "fnol-vehicles-by-brand-id-and-payer":
        case "fnol-count-by-damage_severity-and-payer":
        case "top-ten-experts-by-dispatch-count-and-payer":
        case "fnol-count-by-accident-type-and-payer":
        case "fnol-count-by-accident-time-and-payer":
        case "fnol-count-by-problem-and-payer":
          conditionKeys = ["payer_id"]
          conditionValues = [getMainPayer()?.id]
          break

        default:
          null
          break
      }

      getKPI(slug, {
        condition_keys: conditionKeys,
        condition_values: conditionValues,
        // ...(slug === "gender-count-by-policy-date-range" && {
        //   compare_start_date: moment(dateRange.start_date)
        //     .subtract(1, "year")
        //     .format(APIDateFormat)
        //     .replace(/\//g, "-"),
        //   compare_end_date: moment(dateRange.start_date)
        //     .format(APIDateFormat)
        //     .replace(/\//g, "-"),
        // }),
      })
    })
  }

  //function to render each compnent
  const renderChart = (size, kpiKey, ChartComponent) => (
    <Col md={size}>
      {loadingSlug === kpiKey ? (
        <MultiSkeleton length={8} />
      ) : (
        <Fragment>
          {kpiResponses[kpiKey] && (
            <ChartComponent
              KPI={kpiResponses[kpiKey]}
              key={kpiKey}
              dataColors={dataColors}
            />
          )}
        </Fragment>
      )}
    </Col>
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle title={module?.title} />
          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            {/* Welcome User Card */}
            <CardUser
              setDateRange={setDateRange}
              dateRange={dateRange}
              handleClick={() => getPayerKPI()}
            />
            {/* Loading State */}
            {isLoading ? <ModalSpinner color="primary" /> : null}
          </Row>

          <Row className="mb-3">
            {renderChart(
              6,
              "fnol-count-by-accident-date-and-payer",
              AccidentByDate
            )}
            {renderChart(
              6,
              "top-ten-experts-by-dispatch-count-and-payer",
              TOPTenExperts
            )}
            {renderChart(
              6,
              "fnol-top_regions-by-accident-location-and-payer",
              TopRegions
            )}
            {renderChart(6, "fnol-vehicles-by-brand-id-and-payer", TopBrands)}
            {renderChart(
              6,
              "fnol-count-by-reported-by-id-and-payer",
              ReportedBy
            )}
            {renderChart(
              6,
              "fnol-count-by-damage_severity-and-payer",
              DamageServerity
            )}
            {renderChart(
              6,
              "fnol-count-by-accident-time-and-payer",
              AccidentByTime
            )}
            {renderChart(
              6,
              "fnol-count-by-accident-type-and-payer",
              AccidentByType
            )}
            {renderChart(6, "fnol-count-by-problem-and-payer", ProblemType)}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FnolDashboard
