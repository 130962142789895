import { buttonsTitles } from "common/data"
import React, { Fragment, useState } from "react"
import { Row } from "reactstrap"
import MultiSkeleton from "../Common/MultiSkeleton"
import { FormModal, SearchSelect } from "../Common/Forms"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  NotificationMessage,
  apiErrorrHandler,
  generateYearsOptions,
  isInputInvalid,
} from "utils"
import * as URLs from "helpers/url_helper"
import { calculateCauseSpending } from "helpers/Production/Policies/beneficaries_helper"

const FocSpentModal = ({ onCloseClick, show, beneficiaryId }) => {
  const [causeSpending, setCauseSpending] = useState([])

  const handleCalculateCauseSpending = async (values, setSubmitting) => {
    try {
      const res = await calculateCauseSpending(beneficiaryId, {
        underwriting_year: values.year,
        cause_ids: values.cause_ids,
      })
      setCauseSpending(res.item)
      NotificationMessage("success", "add")
      onCloseClick()
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setSubmitting(false)
    }
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      year: "",
      cause_ids: [],
    },
    validationSchema: Yup.object({
      year: Yup.string(),
      cause_ids: Yup.array(),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleCalculateCauseSpending(values, setSubmitting, resetForm)
    },
  })

  return (
    <FormModal
      modalSize="md"
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={validation.handleSubmit}
      modalTitle="FOC Spent"
      isSaving={validation.isSubmitting}
      saveButtonTitle={buttonsTitles.submit}
    >
      <Fragment>
        <form>
          <Row>
            <SearchSelect
              //   id="1075"
              name="year"
              label="Underwriting Year"
              value={validation.values.year}
              onChange={validation.setFieldValue}
              onBlur={validation.setFieldTouched}
              options={generateYearsOptions(30)}
              invalid={isInputInvalid(validation, "year")}
              error={validation.errors.year}
              wrapperClassNames="mb-3"
            />
            <SearchSelect
              id="415"
              name="cause_ids"
              label="Cause"
              // inputField={fieldFinder(inputs, 415)}
              inputField={{ predefined_value: true }}
              value={validation.values.cause_ids}
              onChange={validation.setFieldValue}
              onBlur={validation.setFieldTouched}
              mappingLabel="name"
              mappingValue="id"
              url={`${URLs.PREDEFINEDS}/235`}
              invalid={isInputInvalid(validation, "cause_ids")}
              error={validation.errors.cause_ids}
              wrapperClassNames="mb-3"
              multiple={true}
            />
          </Row>
        </form>
      </Fragment>
    </FormModal>
  )
}

export default FocSpentModal
