import { ADD_DATA, REMOVE_DATA } from "./actionTypes"

export const addData = payload => {
  return {
    type: ADD_DATA,
    payload: payload,
  }
}

export const removeData = () => {
  return {
    type: REMOVE_DATA,
  }
}
