import getChartColorsArray from "components/Common/ChartsDynamicColor"
import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody } from "reactstrap"

const MedicalCodeChart = ({ dataColors, KPI }) => {
  // var barChartColor = getChartColorsArray(dataColors)
  const medicalCodes = KPI
    ? KPI.data?.flatMap((item) => item?.medical_codes)
    : []

  const uniqueMedicalCodes = medicalCodes.reduce((acc, code) => {
    const existingCode = acc.find((c) => c.name === code.name)
    if (existingCode) {
      existingCode.calculated_value += code.calculated_value
    } else {
      acc.push({ ...code })
    }
    return acc
  }, [])

  const series = [
    {
      name: "",
      data:
        uniqueMedicalCodes?.map((value) => value.calculated_value).length > 0
          ? uniqueMedicalCodes
              .sort((a, b) => a.calculated_value - b.calculated_value)
              ?.map((item) => Number(item.calculated_value.toFixed(2)))
          : [],
    },
  ]

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["rgba(52, 195, 143, 0.85)"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: uniqueMedicalCodes?.map((value) => value.name)
        ? uniqueMedicalCodes?.map((value) => value.name)
        : [],
      // labels: {
      //   formatter: function (value) {
      //     return value?.toLocaleString("en-US")
      //   },
      // },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `$ ${val.toLocaleString("en-US")}`
        },
      },
    },
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title">{KPI?.name}</h4>
          <div>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="350"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MedicalCodeChart
