import { request } from "../../api_helper";
import * as url from "../../url_helper";
/*
Benefecaries
*/

// add new beneficary

// url
export const addBeneficary = (data) =>
  request({ url: url.BENEFECARIES, method: "POST", data });

// get all healthCares with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllBeneficaries = ({
  policy_id,
  // beneficary_id,
  parent_id,
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: url.BENEFECARIES,
    method: "GET",
    params: {
      policy_id: policy_id && policy_id,
      // beneficary_id: beneficary_id && beneficary_id,
      parent_id: parent_id && parent_id,
      ...rest,
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
    },
  });

// update beneficary
//url/{id}
export const updateBeneficary = (data) =>
  request({
    url: `${url.BENEFECARIES}/${data.id}`,
    method: "PUT",
    data,
  });

//beneficiary exclusions
export const BeneficaryExclusions = (beneficiaryId, data) =>
  request({
    url: `${url.BENEFECARIES}/${beneficiaryId}/exclusions`,
    method: "POST",
    data,
  });

// show beneficary
//url/{id}
export const showBeneficary = (beneficaryId) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}`,
    method: "GET",
  });

// delete beneficary
//url/{id}
export const deleteBeneficary = (beneficaryId) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}`,
    method: "DELETE",
  });

// activate beneficary
//url/activate/{id}
export const activateBeneficary = (beneficaryId) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/activate`,
    method: "POST",
  });

// deactivate beneficary
//url/deactivate/{id}
export const deactivateBeneficary = (beneficaryId) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/deactivate`,
    method: "POST",
  });

// get Premiums
// url/beneficiaryId/premiums
export const getPremiums = (beneficaryId, params) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/${url.PREMIUMS}`,
    method: "GET",
    params,
  });

// calculate Premiums
// url/beneficiaryId/calculate-premiums
export const calculatePremiums = (data) =>
  request({
    url: `${url.BENEFECARIES}/${data.beneficaryId}/${url.CALCULATEPREMIUMS}`,
    method: "POST",
    data,
  });

// save Premium
// url/beneficiaryId/premiums
export const savePremium = (beneficaryId, data) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/${url.PREMIUMS}`,
    method: "POST",
    data,
  });

// save multiple Premiums
// url/premiums
export const savePremiums = (data) =>
  request({
    url: `${url.BENEFECARIES}/${url.PREMIUMS}`,
    method: "POST",
    data,
  });

// calculate Endrosement
// url/beneficiaryId/calculate-endorsement
export const calculateEndrosement = (beneficiaryId, data) =>
  request({
    url: `${url.BENEFECARIES}/${beneficiaryId}/${url.CALCULATEENDORSEMENT}`,
    method: "POST",
    data,
  });

// save Endorsement
// url/beneficiaryId/save-endorsement
export const saveEndorsement = (beneficaryId, data) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/${url.SAVEENDORSEMENT}`,
    method: "POST",
    data,
  });

// Show Beneficiary Coparticipation
// url/beneficiaryId/product
export const showBeneficaryCoparticipations = (beneficaryId) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/products`,
    method: "GET",
  });

// Show Beneficiary Speical Notes
// url/beneficiaryId/notes
export const showBeneficarySpecialNotes = (beneficaryId) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/notes`,
    method: "GET",
  });

//Beneficiary Check Eligibility
// url/check-eligibility
export const checkBeneficaryEligibility = (data) =>
  request({
    url: url.BENEFECARIESEligibility,
    method: "POST",
    data,
  });

// Batch Upload Beneficiaries
export const uploadBeneficiaries = (data) =>
  request({ url: `${url.BENEFECARIES}/import`, method: "POST", data });

// save Premium
// url/beneficiaryId/premiums
export const calculateParticipations = (beneficaryId, data) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/${url.PARTICPICATION}`,
    method: "POST",
    data,
  });
// Update Status (white list or Black Lits) healthcare
//url/{id}/statuses
export const updateBeneficaryStatus = (data) =>
  request({
    url: `${url.BENEFECARIES}/${data.id}/statuses`,
    method: "POST",
    data,
  });

// Calculate Limits for Beneficiary
//url/{id}/calculate-limits
export const calculateBeneficaryLimits = (beneficaryId, data) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/calculate-limits`,
    method: "POST",
    data,
  });

// Batch save Premium
// url/premiums
export const batchSavePremium = (data) =>
  request({
    url: `${url.BENEFECARIES}/${url.PREMIUMS}`,
    method: "POST",
    data,
  });

// show beneficary
//url/{id}
export const printBeneficary = (beneficaryId, params) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/schedule-template`,
    method: "GET",
    params,
  });

// print beneficary card
//url/{id}
export const printBeneficaryCard = (beneficaryId) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/card`,
    method: "GET",
  });

// check claim duplication for the beneficiary
// url/beneficiaryId/claim/check-duplication
export const checkClaimDuplication = (beneficaryId, params) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/claim/check-duplication`,
    method: "GET",
    params,
  });

// calculate Cause Spending
export const calculateCauseSpending = (beneficaryId, params) =>
  request({
    url: `${url.BENEFECARIES}/${beneficaryId}/calculate-cause-spending`,
    method: "GET",
    params,
  });
// print bulk card
export const printBulkCard = (params) =>
  request({
    url: `${url.BENEFECARIES}/bulk-card`,
    method: "GET",
    params,
  });

// Search Beneficiary TOB Category
export const getBeneficaryTOBCategory = (beneficiaryId, params) =>
  request({
    url: `${url.BENEFECARIES}/${beneficiaryId}/benefit-category`,
    method: "GET",
    params,
  });
