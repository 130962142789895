import { get, request } from "../api_helper"

import { FNOL } from "../url_helper"

export const getALLFNOL = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: FNOL,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// add Fnol

export const addFnol = (data) => request({ url: FNOL, method: "POST", data })

// show Fnol

export const showFnol = (fnolId) =>
  request({ url: `${FNOL}/${fnolId}`, method: "GET" })

// update Fnol
//url/{id}

export const updateFnol = (data) =>
  request({ url: `${FNOL}/${data.id}`, method: "PUT", data })

// delete Fnol
//url/{id}
export const deleteFnol = (fnolId) =>
  request({
    url: `${FNOL}/${fnolId}`,
    method: "DELETE",
  })

// activate Fnol
//url/activate/{id}
export const activateFnol = (fnolId) =>
  request({
    url: `${FNOL}/${fnolId}/activate`,
    method: "POST",
  })

// deactivate Fnol
//url/deactivate/{id}
export const deactivateFnol = (fnolId) =>
  request({
    url: `${FNOL}/${fnolId}/deactivate`,
    method: "POST",
  })

//upload expert fees
export const uploadExpertFees = (data) =>
  request({
    url: `${FNOL}/expert-fees/import`,
    method: "POST",
    data,
  })
export const findDuplicateFnols = (params) =>
  request({
    url: `${FNOL}/duplicates/by-accident-date`,
    method: "GET",
    params,
  })
