/* Paths */
import { financePaths } from "common/data/routePaths"
import { lazy } from "react"
/*
Components
*/

const Invoice = lazy(() => import("pages/Finance/Invoice"))
const InvoiceForms = lazy(() => import("pages/Finance/Invoice/InvoiceForms"))

// Finance Form
const PaymentOrder = lazy(() => import("pages/Finance/PaymentOrder"))
const PaymentOrderForms = lazy(() =>
  import("pages/Finance/PaymentOrder/PaymentOrderForms")
)
const Receipt = lazy(() => import("pages/Finance/Receipt"))
const ReceiptsFroms = lazy(() => import("pages/Finance/Receipt/ReceiptsFroms"))
const PolicyPayment = lazy(() => import("pages/Finance/PolicyPayment"))
const PolicyPaymentForms = lazy(() =>
  import("pages/Finance/PolicyPayment/PolicyPaymentForms")
)
const CommissionPayment = lazy(() => import("pages/Finance/CommissionPayment"))
const CommissionPaymentForm = lazy(() =>
  import("pages/Finance/CommissionPayment/CommissionPaymentForm")
)
const PolicyPaymentPrint = lazy(() =>
  import("pages/Finance/PolicyPayment/PolicyPaymntPrint/PolicyPaymentPrint")
)
const InvoicePrint = lazy(() =>
  import("pages/Finance/Invoice/InvoicePrint/InvoicePrint")
)
const PaymentPrint = lazy(() =>
  import("pages/Finance/CommissionPayment/CommissionPaymentPrint/PaymentPrint")
)
const POPrint = lazy(() =>
  import("pages/Finance/PaymentOrder/PaymentOrderPrint/POPrint")
)

// finance

// desctructure finance paths
const { invoice, paymentOrder, receipt, policyPayment, commissionPayment } =
  financePaths

export const FinanceRoutes = [
  // invoice
  { path: invoice, component: Invoice },
  // add invoice
  { path: `${invoice}/add`, component: InvoiceForms },
  //edit invoice
  { path: `${invoice}/edit/:id`, component: InvoiceForms },
  // Print Receipt
  { path: `${invoice}/print/:id`, component: InvoicePrint },

  // payment Order
  { path: paymentOrder, component: PaymentOrder },
  // add payment Order
  { path: `${paymentOrder}/add`, component: PaymentOrderForms },
  //edit payment Order
  { path: `${paymentOrder}/edit/:id`, component: PaymentOrderForms },
  //view payment Order
  { path: `${paymentOrder}/view/:id`, component: PaymentOrderForms },
  //print payment Order
  { path: `${paymentOrder}/print/:id`, component: POPrint },

  // Receipts
  { path: receipt, component: Receipt },
  // add Receipt
  { path: `${receipt}/add`, component: ReceiptsFroms },
  //edit Receipt
  { path: `${receipt}/edit/:id`, component: ReceiptsFroms },
  //view Receipt
  { path: `${receipt}/view/:id`, component: ReceiptsFroms },

  // Policy Payments
  { path: policyPayment, component: PolicyPayment },
  // add Receipt
  { path: `${policyPayment}/add`, component: PolicyPaymentForms },
  //edit Receipt
  { path: `${policyPayment}/edit/:id`, component: PolicyPaymentForms },
  //view Receipt
  { path: `${policyPayment}/view/:id`, component: PolicyPaymentForms },
  // Print Receipt
  { path: `${policyPayment}/print/:id`, component: PolicyPaymentPrint },

  //commission payment
  { path: commissionPayment, component: CommissionPayment },
  // add commission payment
  { path: `${commissionPayment}/add`, component: CommissionPaymentForm },
  // edit commission payment
  { path: `${commissionPayment}/edit/:id`, component: CommissionPaymentForm },
  // view commission payment
  { path: `${commissionPayment}/view/:id`, component: CommissionPaymentForm },
  // Print Receipt
  { path: `${commissionPayment}/print/:id`, component: PaymentPrint },
]
