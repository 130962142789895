import React, { Fragment, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
// import { useDispatch } from "react-redux"
import { DeleteModal, ActivationModal } from "../Modals";
// import { notification } from "antd"

// custom hook
import useModuleActions from "hooks/useModuleActions";
// redux
import { useDispatch, useSelector } from "react-redux";

// utils
import {
  beneficaryListModuleId,
  claimFormModuleId,
  eventManagerModuleID,
  policyModuleId,
  predefinedModuleID,
  productionEventsId,
} from "utils/modulesIds";
import PremiumModal from "../Modals/PremiumModal";
import {
  financePaths,
  motorPaths,
  productionPaths,
  stakeholderPaths,
} from "common/data/routePaths";
import SpecialNotesModal from "../Modals/SpecialNotesModal";
import {
  allowedPrintPermissionStatuses,
  buttonsTitles,
  hideProcessingPermissionStatuses,
  modalHeaders,
  modalsStates,
  policyTypes,
} from "common/data";
import PremiumValidationModal from "components/TP/Beneficiary/PremiumValidationModal";
import { SingleRowAction } from ".";
import { MedicalFilesTableModal } from "components/TP/Claims";
import { encryptText } from "utils";
import ClaimChatNotification from "components/TP/Chats/ClaimChatNotification";
import ChatModal from "components/TP/Chats/ChatModal";
import { usePageType } from "hooks";
import RouteToBranchModal from "pages/Motors/FNOL/RouteToBranchModal";
import { addData } from "store/actions";

const RowActions = ({
  cellProps,
  cellProps: {
    row: { original: row },
  },
  onDelete,
  onActivate,
  onClickUpdate,
  onClickView,
  onClickStatus,
  onClickLog,
  onClickDependency,
  onClickCopy,
  onClickStandardCode,
  onClickProcessing,
  onClickPrint,
  subModuleId,
  removeUpdatePermission,
  displayRowActionsInView = false,
}) => {
  const location = useLocation();

  const { isViewPage: isInViewPage } = usePageType();
  // disable view page if the prop is received true
  const isViewPage = isInViewPage && !displayRowActionsInView;

  const pathname = location.pathname;

  //extract the  actions permissions from the custom hook
  const {
    isUpdatePermission,
    isSoftDeletePermission,
    isActivatePermission,
    isLogFilePermission,
    isDeactivatePermission,
    isBlackListPermission,
    isWhiteListPermission,
    isUserActivityPermission,
    isViewDependencyPermission,
    isCopyPermission,
    isViewPermission,
    isBeneficaryViewPremiumPermission,
    isStandardCodePermission,
    isViewProcessingPermission,
    isProductAddPolicyPermission,
    isPolicyAddBeneficiaryPermission,
    isBeneficiarySpecialNotesPermission,
    isPolicyRenewalPermission,
    isViewEndorsementPermission,
    isBatchUploadPermission,
    isPrintPermission,
    isPrintInvoicePermission,
  } = useModuleActions(subModuleId && subModuleId);

  const dispatch = useDispatch();

  const { claimFormData } = useSelector((state) => ({
    moduleId: state?.Module?.actions?.id,
    claimFormData: state.SharedData?.claimFormData,
  }));

  // get the module id form global state

  const moduleId = useSelector((state) => state?.Module?.actions?.id);

  const isPredefinedModule = moduleId === predefinedModuleID;

  // states

  // to toggle the delete modal
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  // to toggle the activation modal
  const [isActivationModal, setIsActivationModal] = useState(false);
  // to toggle the premium modal
  const [isPremiumModal, setIsPremiumModal] = useState(false);
  // to toggle the validate premium modal
  const [isValidatePremiumModal, setIsValidatePremiumModal] = useState(false);

  // to toggle the special Notes modal
  const [isNotesModal, setIsNotesModal] = useState(false);

  // to toggle change branch modal
  const [isChangeBranchModal, setIsChangeBranchModal] = useState(false);

  const [isModal, setIsModal] = useState(false);

  return (
    !isViewPage && (
      <Fragment>
        <div className="d-flex gap-2">
          {/* View icon */}

          {isViewPermission && (
            <SingleRowAction
              onClick={onClickView}
              iconClass="mdi mdi-eye"
              actionName={isViewPermission?.name || "View"}
              record={row}
            />
          )}

          {/* Edit icon */}

          {isUpdatePermission && !removeUpdatePermission && onClickUpdate && (
            <SingleRowAction
              onClick={onClickUpdate}
              color="text-primary"
              iconClass="mdi mdi-pencil"
              actionName={isUpdatePermission?.name}
              record={row}
            />
          )}

          {/* Deactivate Icon */}

          {isDeactivatePermission &&
            row?.is_active === 1 &&
            // hide the icon (in predefined Module) when group is null
            (isPredefinedModule ? (row?.group ? true : false) : true) && (
              <SingleRowAction
                onClick={() => setIsActivationModal(true)}
                color="text-info"
                iconClass="mdi mdi-checkbox-marked-circle"
                actionName={isDeactivatePermission?.name}
                record={row}
              />
            )}

          {/* Activate Icon */}

          {isActivatePermission && row?.is_active === 0 && (
            <SingleRowAction
              onClick={() => setIsActivationModal(true)}
              color="text-danger"
              iconClass="mdi mdi-checkbox-marked-circle"
              actionName={isActivatePermission?.name}
              record={row}
            />
          )}

          {/* Delete Icon */}

          {isSoftDeletePermission &&
            // hide the icon (in predefined Module) when group is null
            (isPredefinedModule ? (row?.group ? true : false) : true) &&
            // hide the delete icon in event manger and production events module when is_executed === 1
            (moduleId === eventManagerModuleID ||
            moduleId === productionEventsId
              ? !row.is_executed
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={() => setIsDeleteModal(true)}
                color="text-danger"
                iconClass="mdi mdi mdi-close"
                actionName={isSoftDeletePermission?.name}
                record={row}
              />
            )}

          {/* log history icon */}

          {isLogFilePermission && (
            <SingleRowAction
              onClick={onClickLog}
              // color="text-danger"
              iconClass="mdi mdi-history"
              actionName={isLogFilePermission?.name}
              record={row}
            />
          )}
          {/* claim chat in claim only */}

          {moduleId === claimFormModuleId && (
            <span className="position-relative d-flex align-items-center justify-content-center">
              <SingleRowAction
                onClick={() => {
                  setIsModal(modalsStates.chatModal);

                  // disable the claim relod when open the chat
                  dispatch(
                    addData({
                      claimFormData: {
                        ...claimFormData,
                        disableClaimReload: true,
                      },
                    })
                  );
                }}
                // color="text-danger"
                iconClass="bx bxs-chat"
                actionName={buttonsTitles.chat}
                record={row}
              />
              <ClaimChatNotification claim={row} />
            </span>
          )}

          {(isPrintPermission ||
            (moduleId === beneficaryListModuleId &&
            row?.is_active &&
            !row?.group
              ? true
              : false)) &&
            (moduleId === claimFormModuleId
              ? allowedPrintPermissionStatuses.includes(row?.status?.id)
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickPrint}
                // color="text-danger"
                iconClass="mdi mdi-cloud-print-outline"
                actionName={isPrintPermission?.name || "print contract"}
                record={row}
              />
            )}

          {/* User Activity  icon */}

          {isUserActivityPermission && (
            <SingleRowAction
              to={`/users/${row?.id}/activity`}
              target="_blank"
              iconClass="mdi mdi-account-search"
              actionName={isUserActivityPermission?.name}
              record={row}
            />
          )}

          {/* Black List icon */}

          {isBlackListPermission &&
            (row?.status?.id === 116 || !row?.status) &&
            // hide in the beneficiary is he is not active
            (moduleId === beneficaryListModuleId
              ? row?.is_active
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickStatus}
                iconClass="mdi mdi-tooltip-remove"
                actionName={isBlackListPermission?.name}
                record={row}
              />
            )}

          {/* White List icon */}

          {isWhiteListPermission &&
            row?.status?.id === 115 &&
            // hide in the beneficiary is he is not active
            (moduleId === beneficaryListModuleId
              ? row?.is_active
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickStatus}
                color="text-danger"
                iconClass="mdi mdi-tooltip-check"
                actionName={isWhiteListPermission?.name}
                record={row}
              />
            )}

          {/* Dependency (Sub Beneficary) icon (show for principel beneficary onle) */}

          {isViewDependencyPermission &&
            !row?.group &&
            row?.policy?.type?.slug !== policyTypes.invidual?.slug && (
              <SingleRowAction
                onClick={onClickDependency}
                iconClass="mdi mdi-account-group"
                actionName={isViewDependencyPermission?.name}
                record={row}
              />
            )}

          {/*Beneficary View Premium icon */}

          {isBeneficaryViewPremiumPermission && row?.is_active === 1 && (
            <SingleRowAction
              onClick={() => setIsPremiumModal(true)}
              iconClass="mdi mdi-calculator-variant-outline"
              actionName={isBeneficaryViewPremiumPermission?.name}
              record={row}
            />
          )}

          {/*Beneficary View Endorsement icon */}

          {isViewEndorsementPermission && (
            <SingleRowAction
              to={
                row?.policy
                  ? `/policy-endorsement/${row?.id}/${row?.policy?.id}`
                  : `/policy-endorsement/${row?.id}`
              }
              target="_blank"
              iconClass="mdi mdi-alpha-e-box-outline"
              actionName={isViewEndorsementPermission?.name}
              record={row}
            />
          )}

          {/*Beneficary Special Notes  icon */}

          {/* {isBeneficiarySpecialNotesPermission && (
            <SingleRowAction
              onClick={onClickSpeicalNotes}
              iconClass="mdi mdi-android-messages"
              actionName={isBeneficiarySpecialNotesPermission?.name}
              record={row}
            />
          )} */}

          {/*Standard Code Permission in priority payer */}

          {isStandardCodePermission && (
            <SingleRowAction
              onClick={onClickStandardCode}
              iconClass="mdi mdi-google-spreadsheet"
              actionName={isStandardCodePermission?.name}
              record={row}
            />
          )}

          {/* Copy (clone) icon */}

          {isCopyPermission && moduleId !== policyModuleId && (
            <SingleRowAction
              onClick={onClickCopy}
              iconClass="mdi mdi-content-copy"
              actionName={isCopyPermission?.name}
              record={row}
            />
          )}

          {/* Processing icon for claim */}
          {/* It will be displayed only if claim.status.id === 267 (received Claim Only) */}
          {isViewProcessingPermission &&
            !hideProcessingPermissionStatuses.includes(row?.status?.id) && (
              <SingleRowAction
                onClick={onClickProcessing}
                iconClass="mdi mdi-calculator"
                actionName={isViewProcessingPermission?.name}
                record={row}
              />
            )}

          {/* Batch Upload icon (appear here for just policy module) */}

          {/* {isBatchUploadPermission && moduleId === policyModuleId && ( */}
          {isBatchUploadPermission && pathname === productionPaths.policy && (
            <SingleRowAction
              to={`beneficiaries/${row?.id}/import`}
              iconClass="mdi mdi-cloud-upload"
              actionName={isBatchUploadPermission?.name}
              record={row}
            />
          )}

          {/* Product Add Policy icon */}

          {isProductAddPolicyPermission && (
            <SingleRowAction
              to={{
                pathname: `${productionPaths.policy}/add`,
                state: {
                  product_id: row?.id,
                  payer_id: row?.payer?.id,
                },
              }}
              iconClass="mdi mdi-shield-plus"
              actionName={isProductAddPolicyPermission?.name}
              record={row}
            />
          )}

          {/* Policy Add Beneficiary icon */}

          {isPolicyAddBeneficiaryPermission && (
            <SingleRowAction
              to={`${stakeholderPaths.beneficiaryList}/${row?.id}/add`}
              iconClass="mdi mdi-account-plus"
              actionName={isPolicyAddBeneficiaryPermission?.name}
              record={row}
            />
          )}

          {/* Policy Add Beneficiary icon */}
          {/* policy renewal for all policies except the inProgress */}

          {isPolicyRenewalPermission &&
            row.status?.slug !== "policyStatus-inprogress" && (
              <SingleRowAction
                to={{
                  pathname: `${stakeholderPaths.beneficiaryList}/renewal`,
                  // state: { policy_id: row?.id },
                  // send the policy in the URL State
                  state: { policy: row },
                }}
                iconClass="mdi mdi-alpha-r-box"
                actionName={isPolicyRenewalPermission?.name}
                record={row}
              />
            )}
          {/* Beneficiary Print Invoice icon */}

          {/* {isPrintInvoicePermission && (
            <SingleRowAction
              to={`${financePaths.invoice}/print/${row?.id}`}
              iconClass="mdi mdi-receipt"
              target="_blank"
              actionName={isPrintInvoicePermission?.name}
              record={row}
            />
          )} */}

          {/* For Beneficiary List Only */}
          {moduleId === beneficaryListModuleId && (
            <SingleRowAction
              onClick={() => {
                setIsModal(modalsStates.medicalFilesModal);
              }}
              iconClass="mdi mdi-file-document-multiple-outline"
              actionName={modalHeaders.medicalFile}
              record={row}
            />
          )}

          {/* Print Cards (for Policy) */}
          {pathname === productionPaths.policy && (
            <SingleRowAction
              to={`${stakeholderPaths.beneficiaryList}/${
                stakeholderPaths.bulkPrintCards
              }/${encryptText(row.id)}`}
              target="_blank"
              iconClass="mdi mdi-cards"
              actionName="Print Cards"
              record={row}
            />
          )}
          {pathname === motorPaths.fnol && (
            <SingleRowAction
              onClick={() => {
                setIsChangeBranchModal(true);
              }}
              iconClass="mdi mdi-reload"
              actionName="Change Branch"
              record={row}
            />
          )}
        </div>

        <DeleteModal
          show={isDeleteModal}
          onDeleteClick={() => {
            onDelete();
            setIsDeleteModal(false);
          }}
          onCloseClick={() => setIsDeleteModal(false)}
        />

        <ActivationModal
          show={isActivationModal}
          record={row}
          setIsActivationModal={setIsActivationModal}
          onConfirmClick={() => {
            onActivate();
            setIsActivationModal(false);
          }}
          onCloseClick={() => setIsActivationModal(false)}
        />

        {isPremiumModal && (
          <PremiumModal
            isPremiumModal={isPremiumModal}
            setIsPremiumModal={setIsPremiumModal}
            // beneficaryId={recordId}
            beneficaryId={row.id}
            policy={row.policy}
          />
        )}
        {isNotesModal && (
          <SpecialNotesModal
            show={isNotesModal}
            onCloseClick={() => setIsNotesModal(false)}
            beneficaryId={row.id}
            beneficary={row}
          />
        )}

        {isValidatePremiumModal && (
          <PremiumValidationModal
            isModal={isValidatePremiumModal}
            setIsModal={setIsValidatePremiumModal}
            beneficiary={row}
          />
        )}
        {isModal === modalsStates.medicalFilesModal && (
          <MedicalFilesTableModal
            show={isModal === modalsStates.medicalFilesModal}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={row?.id}
            beneficiary={row}
            notInClaim={true}
          />
        )}

        {/* Chat Modal */}
        {isModal === modalsStates.chatModal && (
          <ChatModal
            show={isModal === modalsStates.chatModal}
            onCloseClick={() => {
              setIsModal(false);
              // enable the claim relod when close the chat
              dispatch(
                addData({
                  claimFormData: {
                    ...claimFormData,
                    disableClaimReload: false,
                  },
                })
              );
            }}
            resourceId={row?.id}
            beneficiary={row?.beneficiary}
            claim={row}
          />
        )}
        {/* route to branch Modal- fnol only */}
        {isChangeBranchModal && (
          <RouteToBranchModal
            isModal={isChangeBranchModal}
            setIsModal={setIsChangeBranchModal}
            rowId={row?.id}
          />
        )}
      </Fragment>
    )
  );
};

export default RowActions;
